import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Hidden, DialogActions, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { dialogCss } from './dialogCss';
import { LoadingButton } from '../Shared';
import { DialogAttachment } from './DialogAttachment';
import { useCreateIdeaController } from './hooks/useCreateIdeaController';
import config from '../../config';

export function CreateIdeaActions({ name, onSubmit }) {
  const { t } = useTranslation();
  const dialogClasses = dialogCss();
  const {
    previous,
    createDraft,
    redirectToReviewIdea,
    loading,
    ideaCreate,
    isSendIdeaDialog
  } = useCreateIdeaController();
  const { fromHistory } = useSelector(state => state.ideaCreateDialogs);

  return (
    <DialogActions
      className={dialogClasses.containerAction}
      style={{ justifyContent: isSendIdeaDialog ? name === 'DIALOG_TITLE_DESCRIPTION' ? 'space-between' : 'flex-end' : 'space-between' }}
    >
      
      {['DIALOG_TITLE_DESCRIPTION'].includes(name) && (
        <DialogAttachment from="DIALOG_TITLE_DESCRIPTION" />
      )}
      
      {!isSendIdeaDialog && (
        fromHistory.length > 0 && (
          <Button
            color="primary"
            onClick={previous}
            startIcon={<ArrowBackIcon />}
            className={dialogClasses.button}
          >
            {t('voltar')}
          </Button>
        )
      )}

      {loading ? (
        <LoadingButton />
      ) : (
        <Box>
          {!isSendIdeaDialog && (
            <Hidden smDown>
              <Button
                className={dialogClasses.button}
                variant="outlined"
                color="primary"
                type="submit"
                onClick={createDraft}
              >
                {t('salvar_rascunho')}
              </Button>
            </Hidden>
          )}
          <Button
            className={dialogClasses.button}
            variant="contained"
            color="primary"
            style={{ marginLeft: 8 }}
            startIcon={!isSendIdeaDialog && <ArrowForwardIcon />}
            onClick={isSendIdeaDialog ? redirectToReviewIdea : onSubmit}
            disabled={config.ALLOW_IDEIAS_WITHOUT_CHALLENGE === false && ideaCreate?.challenge?.name === 'Ideia Aberta'}
          >
            {isSendIdeaDialog ? t('salvar_edicao') : t('avancar')}
          </Button>
        </Box>
      )}
    </DialogActions>
  );
}