import { styled } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions';

export const Dialog = styled(MuiDialog)({
  '& .MuiDialog-paperWidthSm': {
    maxWidth: 576
  }
});

export const DialogTitle = styled(MuiDialogTitle)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 'bold'
});

export const DialogContent = styled(MuiDialogContent)({
  borderBottom: 'none'
});

export const DialogActions = styled(MuiDialogActions)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '16px 24px',
});