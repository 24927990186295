export const evaluationTeamsConstants = {
  CREATE_EVALUATION_TEAM_REQUEST: 'CREATE_EVALUATION_TEAM_REQUEST',
  CREATE_EVALUATION_TEAM_SUCCESS: 'CREATE_EVALUATION_TEAM_SUCCESS',
  CREATE_EVALUATION_TEAM_FAILURE: 'CREATE_EVALUATION_TEAM_FAILURE',

  GET_ALL_EVALUATION_TEAMS_REQUEST: 'GET_ALL_EVALUATION_TEAMS_REQUEST',
  GET_ALL_EVALUATION_TEAMS_SUCCESS: 'GET_ALL_EVALUATION_TEAMS_SUCCESS',
  GET_ALL_EVALUATION_TEAMS_FAILURE: 'GET_ALL_EVALUATION_TEAMS_FAILURE',

  DELETE_EVALUATION_TEAM_REQUEST: 'DELETE_EVALUATION_TEAM_REQUEST',
  DELETE_EVALUATION_TEAM_SUCCESS: 'DELETE_EVALUATION_TEAM_SUCCESS',
  DELETE_EVALUATION_TEAM_FAILURE: 'DELETE_EVALUATION_TEAM_FAILURE',

  UPDATE_EVALUATION_TEAM_REQUEST: 'UPDATE_EVALUATION_TEAM_REQUEST',
  UPDATE_EVALUATION_TEAM_SUCCESS: 'UPDATE_EVALUATION_TEAM_SUCCESS',
  UPDATE_EVALUATION_TEAM_FAILURE: 'UPDATE_EVALUATION_TEAM_FAILURE',

  SET_EVALUATION_TEAM_NAME: 'SET_EVALUATION_TEAM_NAME',
  CHANGE_TEAM_STATUS: 'CHANGE_TEAM_STATUS',
  ADD_PARTICIPANT_FIELD: 'ADD_PARTICIPANT_FIELD',
  REMOVE_PARTICIPANT_FIELD: 'REMOVE_PARTICIPANT_FIELD',
  CLEAR_DIALOG_CREATE_TEAM: 'CLEAR_DIALOG_CREATE_TEAM',
  LOAD_PARTICIPANTS: 'LOAD_PARTICIPANTS',
  LOAD_EVALUATION_TEAM_IN_DIALOG: 'LOAD_EVALUATION_TEAM_IN_DIALOG',
  EVALUATION_TEAM_CHANGE_PARTICIPANT_FIELD: 'EVALUATION_TEAM_CHANGE_PARTICIPANT_FIELD',
  CLEAR_EVALUATION_TEAM_PARTICIPANT_FIELD: 'CLEAR_EVALUATION_TEAM_PARTICIPANT_FIELD'
};
