import React from 'react';

import { Avatar, useTheme } from "@material-ui/core"

export function RoundedIcon({ children, backgroundColor }) {
  const theme = useTheme();
  return (
    <Avatar style={{
      marginRight: 10,
      backgroundColor: backgroundColor ? backgroundColor : theme.palette.primary.light,
    }}>
      {children}
    </Avatar>
  );
}