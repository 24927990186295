import React, { useState, useEffect, Fragment } from 'react';
import { Box, Grid, Typography, IconButton } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EditIcon from '@material-ui/icons/Edit';
import { Link, Image, Video, CardItem } from './styles';
import { truncate } from '../../../helpers';

export function FieldDinamic({ name, label, onClick, value, subTitle }) {
  const [titleItems, setTitleItems] = useState([]);
  const [valueItems, setValueItems] = useState([]);
  const [colsItems, setColsItems] = useState();

  useEffect(() => {
    setValueItems(value ?? []);
    setTitleItems(subTitle ?? []);

    switch(titleItems?.length) {
      case 1:
        return setColsItems(12);
      
      case 2:
        return setColsItems(6);

      case 3:
        return setColsItems(4);

      case 4:
        return setColsItems(3);

      default:
        return setColsItems(12);
    }
  }, [value, subTitle]);

  return (
    value?.length > 0 && (
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle2" color="primary">{label}</Typography>
          <IconButton onClick={onClick}>
            <EditIcon />
          </IconButton>
        </Box>

        {['title', 'description', 'problem', 'solution', 'challenge'].includes(name) && (
          <Typography variant="body2">{value}</Typography>
        )}

        {['participants'].includes(name) && (
          <Grid container  spacing={1}>
            {valueItems.map((item, index) => (
              <Grid item key={index}>
                <Typography variant="body2">{item.display}</Typography>
              </Grid>
            ))}
          </Grid>
        )}

        {['images'].includes(name) && (
          <Grid container spacing={2}>
            {valueItems.map((item, index) => (
              <Grid item xs={4} key={index}>
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                  <Image src={item.preview} />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}

        {['video'].includes(name) && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <Video src={value} height="97" />
              </Box>
            </Grid>
          </Grid>
        )}

        {['files'].includes(name) && (
          <Grid container spacing={2}>
            {valueItems.map((item, index) => (
              <Grid item xs={6} key={index}>
                <CardItem>
                  <DescriptionOutlinedIcon />
                  <Box>
                    <Typography variant="subtitle2">
                      {truncate(item.name, 20)}
                    </Typography>
                    <Typography variant="body2" style={{ color: '#868E96' }}>
                      {item.readableSize}
                    </Typography>
                  </Box>
                </CardItem>
              </Grid>
            ))}
          </Grid>
        )}

        {['links'].includes(name) && (
          <Grid container spacing={1}>
            {valueItems.map((item, index) => (
              <Grid item xs={12} key={index}>
                <Link href={item} target="_black">
                  {item}
                </Link>
              </Grid>
            ))}
          </Grid>
        )}

        {['costs', 'area', 'classification'].includes(name) && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {titleItems.map((item, index) => (
                  <Grid item xs={colsItems} key={index}>
                    <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                      {item}
                    </Typography>
                  </Grid>
                ))}
                
                {name !== 'area' && valueItems.map((item, index) => (
                  <Grid item xs={colsItems} key={index}>
                    <Typography variant="body2">
                      {item?.name ?? item}
                    </Typography>
                  </Grid>
                ))}

                {name === 'area' && valueItems.map((item, index) => (
                  <Fragment key={index}>
                    <Grid item xs={4}>
                      <Typography variant="body2">
                        {item?.businessUnit.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} key={index}>
                      <Typography variant="body2">
                        {item?.local.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} key={index}>
                      <Typography variant="body2">
                        {item?.occupationArea.name}
                      </Typography>
                    </Grid>
                  </Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    )
  );
}