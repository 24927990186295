import { ideaGeneralEvaluationConstants } from '../constants';
import { ideaGeneralEvaluationService } from '../services';
import { alertActions } from './alert.actions';

export const ideaGeneralEvaluationActions = {
  getEvaluationsByGroup,
  getIndicatorsByIdea,
  getCommentsByIdea,
  getEvaluationByIdeaIdAndUser,
  createRatesAndComment,
  setEvaluationComment,
  setEvaluationRate,
}

function getEvaluationsByGroup(groupId) {
  return dispatch => {
    dispatch({ type: ideaGeneralEvaluationConstants.CLEAR_GENERAL_EVALUATIONS_COMMENT_FORM })
    dispatch({ type: ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_REQUEST });
    ideaGeneralEvaluationService.getEvaluationsByGroup(groupId)
      .then(({ data }) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_EVALUATIONS_BY_GROUP_FAILURE,
          error: error
        });
      })
  }
}

function getIndicatorsByIdea(ideaId) {
  return dispatch => {
    dispatch({ type: ideaGeneralEvaluationConstants.GET_INDICATORS_BY_IDEA_REQUEST });
    ideaGeneralEvaluationService.getIndicatorsByIdea(ideaId)
      .then(({ data }) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_INDICATORS_BY_IDEA_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_INDICATORS_BY_IDEA_FAILURE,
          error: error
        });
      })
  }
}

function getCommentsByIdea(ideaId) {
  return dispatch => {
    dispatch({ type: ideaGeneralEvaluationConstants.GET_COMMENTS_BY_IDEA_REQUEST });
    ideaGeneralEvaluationService.getCommentsByIdea(ideaId)
      .then(({ data }) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_COMMENTS_BY_IDEA_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_COMMENTS_BY_IDEA_FAILURE,
          error: error
        });
      })
  }
}

function getEvaluationByIdeaIdAndUser(ideaId) {
  return dispatch => {
    dispatch({ type: ideaGeneralEvaluationConstants.GET_EVALUATION_BY_IDEAID_AND_USER_REQUEST });
    ideaGeneralEvaluationService.getEvaluationByIdeaIdAndUser(ideaId)
      .then(({ data }) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_EVALUATION_BY_IDEAID_AND_USER_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.GET_EVALUATION_BY_IDEAID_AND_USER_FAILURE,
          error: error
        });
      })
  }
}

function createRatesAndComment(data, successMessage, ideaId) {
  return dispatch => {
    dispatch({ type: ideaGeneralEvaluationConstants.CREATE_RATES_AND_COMMENT_IN_IDEA_REQUEST });
    ideaGeneralEvaluationService.createRatesAndComment(data)
      .then(() => {
        dispatch({ type: ideaGeneralEvaluationConstants.CREATE_RATES_AND_COMMENT_IN_IDEA_SUCCESS });
        dispatch(alertActions.success(successMessage));
        dispatch(getEvaluationByIdeaIdAndUser(ideaId));
      })
      .catch((error) => {
        dispatch({
          type: ideaGeneralEvaluationConstants.CREATE_RATES_AND_COMMENT_IN_IDEA_FAILURE,
          error: error
        });
      })
  }
}

function setEvaluationComment(name, value) {
  return dispatch => {
    dispatch({
      type: ideaGeneralEvaluationConstants.SET_EVALUATION_COMMENT,
      payload: { name, value }
    });
  }
}

function setEvaluationRate(personalizedEvaluation, newValue) {
  return dispatch => {
    dispatch({
      type: ideaGeneralEvaluationConstants.SET_EVALUATION_RATES,
      payload: { personalizedEvaluation, newValue }
    })
  }
}
