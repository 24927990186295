import React, { Fragment } from 'react';
import { Grid, Button, Box, Typography, makeStyles } from '@material-ui/core';
import {
  DeadlineCollapse,
  DetailsCollapse,
  FinancialFeedbackCollapse,
  CostStructureCollapse,
  DialogJustification,
  CardIdeaToProject
} from '../../components';
import ForwardIcon from '@material-ui/icons/Forward';
import { useTranslation } from 'react-i18next';
import { ButtonWithTooltip, LoadingButton } from '../Shared';
import { DialogExecutionFlow } from '../Shared';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import config from '../../config';
import { useIdeaExecutionController } from './hooks';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: theme.spacing(2, 2, 1),
    color: theme.palette.neutrals.low.dark
  }
}));

function IdeaExecution() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    id,
    loading,
    dialogIsOpen,
    estimatedStartDate,
    estimatedConclusionDate,
    executionDetails,
    openModelJustification,
    openDialogJustification,
    onCloseDialogJustification,
    sendExecution,
    openDialog,
    closeDialog
  } = useIdeaExecutionController();

  return (
    <Fragment>
      <DialogExecutionFlow
        open={dialogIsOpen}
        onClose={closeDialog}
        title={t('executar_ideia')}
        subtitle={t('deseja_executar_essa_ideia')}
        description={t('as_informacoes_do_planejamento_da_execução_nao_poderao_ser_editadas_posteriormente')}
      >
        {loading ?
          <LoadingButton />
        :
          <Box>
            <Button variant="outlined" color="primary" onClick={closeDialog}>
              {t('voltar')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 23 }}
              onClick={sendExecution}
            >
              {t('executar')}
            </Button>
          </Box>
        }
      </DialogExecutionFlow>
      <Grid container spacing={4}>
        <Typography variant="subtitle2" className={classes.title}>
          {t('execucao')}
        </Typography>
        <Grid item xs={12}>
        { config.SHOW_PORTFOLIO_IDEA_EXECUTION && <CardIdeaToProject /> }
        </Grid>
        <Grid item xs={12}>
          <DeadlineCollapse />
        </Grid>
        <Grid item xs={12}>
          <CostStructureCollapse />
        </Grid>
        <Grid item xs={12}>
          <FinancialFeedbackCollapse />
        </Grid>
        <Grid item xs={12}>
          <DetailsCollapse />
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='flex-end' alignItems='center'>
              <ButtonWithTooltip
                  tooltip={t('aprovar_solicitar_investimento_ou_estudo')}
                  text={t('execucao_nao_concluida')}
                  variant="outlined"
                  color="primary"
                  startIcon={<CancelPresentationIcon />}
                  onClick={openModelJustification}
                  style={{ marginRight: 10 }}
                />
            <DialogJustification
              open={openDialogJustification}
              onClose={onCloseDialogJustification}
              id={id}
            />
            <Button
              variant="contained"
              color="primary"
              startIcon={<ForwardIcon />}
              onClick={openDialog}
              disabled={(
                executionDetails === "" ||
                estimatedStartDate === null ||
                estimatedConclusionDate === null
              )}
            >
              {t('executar_ideia')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export { IdeaExecution };
