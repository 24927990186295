import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import ImageIcon from '@material-ui/icons/Image';

import { MenuInformation } from '../../Shared';
import { Card, GridFlex, AvatarIcon, EvaluationIcon, GridIndicators, BorderLinearProgress } from './styles';
import { ReactComponent as ChecklistIcon } from '../../../assets/imgs/checklist-icon.svg';

export function IdeaEvaluationDetails({ groupName }) {
  const { t } = useTranslation();
  const { evaluationDetails } = useSelector(state => state.ideaGeneralEvaluation);

  function roundValue(value) {
    return (Math.round(value * 100) / 100).toFixed(2);
  }

  return (
    <Card elevation={0}>
      <Grid container spacing={3}>
        <GridFlex item xs={12} style={{ paddingBottom: 32 }}>
          <AvatarIcon>
            <ChecklistIcon />
          </AvatarIcon>
          <Typography variant="h3">{groupName}</Typography>
        </GridFlex>
        {evaluationDetails?.personalizedEvaluationRates?.map((item, index) => (
          <GridIndicators key={index} item xs={12}>
            <Box display="flex" alignItems="center">
              <EvaluationIcon
                src={item?.personalizedEvaluation?.attachment?.url} 
                alt="evaluation-icon"
                children={<ImageIcon fontSize="small"/>}
              />
              <Typography variant="subtitle1">{item.personalizedEvaluation.name}</Typography>
            </Box>
            <Box width="50%" display="flex" alignItems="center">
              <MenuInformation>{item.personalizedEvaluation.description}</MenuInformation>
              <BorderLinearProgress variant="determinate" value={20 * item.rate} />
              <Typography variant="subtitle2">{roundValue(item.rate)}</Typography>
            </Box>
          </GridIndicators>
        ))}
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle2">{t('comentario')}:</Typography>
          <Typography variant="body2">
            {evaluationDetails?.personalizedEvaluationComment?.comment}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
}