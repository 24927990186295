import { styled } from '@material-ui/core/styles';
import MuiCard from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import MuiDivider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4),
}));

export const Header = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 50
});

export const AvatarIcon = styled(Avatar)(({ theme }) => ({
  background: theme.palette.primary.light,
  color: theme.palette.primary.main,
  marginRight: 16
}));

export const EvaluationIcon = styled(Avatar)(({ theme }) => ({
  background: theme.palette.primary.light,
  color: theme.palette.primary.main,
  width: 32,
  height: 32
}));

export const GridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
});

export const Divider = styled(MuiDivider)(({ theme }) => ({
  marginBottom: 12,
  color: theme.palette.neutrals.high.medium
}));

