import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { Tabs, Tab } from './styles';
import { ReactComponent as InventoryIcon } from '../../../assets/imgs/inventory-icon.svg';
import { ReactComponent as TopicIcon } from '../../../assets/imgs/topic-icon.svg';

export function IdeaGeneralEvaluationTabs({ handleChangeTab, selectedTab }) {
  const { t } = useTranslation();

  return (
    <Tabs
      variant="fullWidth"
      indicatorColor="primary"
      aria-label="general evaluation menu tabs"
      onChange={(e, value) => handleChangeTab(value)}
      value={selectedTab}
    >
      <Tab 
        value="evaluation" 
        label={
          <Box display="flex" alignItems="center" gap="8px">
            <InventoryIcon style={{ marginRight: 8 }} />
            <Typography variant="subtitle2">{t('avaliacao')}</Typography>
          </Box>
        } 
      />
      <Tab value="follow-up" label={
        <Box display="flex" alignItems="center" gap={16}>
          <TopicIcon style={{ marginRight: 8 }} />
          <Typography variant="subtitle2">{t('acompanhamento')}</Typography>
        </Box>
      } />
    </Tabs>
  );
}




