import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import {
  Box,
  Tabs,
  Tab,
  makeStyles,
  Typography,
} from "@material-ui/core";
import TableChartIcon from '@material-ui/icons/TableChart';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import config from '../../config';
import { useWindowSize } from '../../hooks';
import { reportActions } from "../../actions";
import { GraphicPanel, PieChartImagine } from "../../components";
import { TablePanel, ReportCards, ReportLayout } from "../../components";

const useStyles = makeStyles((theme) => ({
  tabs: {
    border: `1px solid ${theme.palette.grey.main}`,
    borderRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    background: 'white',
  },
  itemTab: {
    fontsize: 14,
    fontWeight: 600,
    textTransform: "lowercase",
    marginLeft: theme.spacing(0.8)
  },
}))

export function ReportIdeasWithEvaluatedSteps() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [arrItensCard, setArrItensCArd] = useState({
    cardContent: [],
    dialogContent: []
  })
  const [width] = useWindowSize();
  const {
    tab,
    report,
    chart,
  } = useSelector(state => state.report);
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const tableInfo = {
    columns: [
      { id: 'ideaName', label: t('nome_da_ideia') },
      { id: 'code', label: t('codigo') },
      { id: 'stepDate', label: t('data_da_etapa') },
    ],
    data: report.map(item => item),
  }

  useEffect(() => {
    setArrItensCArd({
      dialogContent: report.map(item => item)
    })
  }, [report])

  useEffect(() => {
    return () => {
      dispatch(reportActions.clearAll())
    }
  }, [dispatch])

  function handleChangeTab(index) {
    dispatch(reportActions.changeTab(index === 0 ? "table" : "graph"));
    setTabIndex(index)
  }

  return (
    <ReportLayout title={t('etapas_avaliadas')} tableInfo={tableInfo.columns}>
      <Tabs
        className={classes.tabs}
        value={tabIndex}
        onChange={(e, value) => handleChangeTab(value)}
        aria-label="Tabs de grafico e tabela"
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab
          aria-controls="Panel tabela"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <TableChartIcon />
              <Typography className={classes.itemTab}>
                {t('tabela')}
              </Typography>
            </Box>
          )}
        />
        <Tab
          aria-controls="Panel grafico"
          label={(
            <Box display="flex" alignItems="center" justifyContent="center">
              <DonutLargeIcon />
              <Typography className={classes.itemTab}>
                {t('grafico')}
              </Typography>
            </Box>
          )}
        />
      </Tabs>

      {tab === "table" && (
        <Fragment>
          {width > config.RESPONSIVE_MOBILE ?
            <TablePanel tableInfo={tableInfo} /> :
            <ReportCards arrItems={arrItensCard} />
          }
        </Fragment>
      )}

      {tab === "graph" && (
        <GraphicPanel>
          <PieChartImagine data={chart} />
        </GraphicPanel>
      )}
    </ReportLayout>
  );
}
