import React from 'react';

import { 
  Box,
  Icon,
  Divider,
  ButtonBase,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import MuiMenu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import config from '../../config';
import { useWindowSize } from '../../hooks';

const useStyles = makeStyles((theme) => ({
  buttoBase: {
    height: 52,
    borderRadius: 4,
    backgroundColor: theme.palette.neutrals.high.light,
  },
  buttonBaseText: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark,
    padding: '0 13px',
  },
  colorIcon: {
    color: theme.palette.neutrals.low.dark,
  },
  tileModal: {
    color: theme.palette.neutrals.low.light,
    padding: theme.spacing(2),
  },
}));

const Menu = withStyles({
  list: {
    padding: 0,
    maxWidth: '888px'
  },
})(MuiMenu);

export function ButtonBaseMenu({ click, open, onClose, anchorEl, icon, titleButton, titleMenu, children, disabled }) {
  const classes = useStyles();
  const [width] = useWindowSize();

  return (
    <>
      <ButtonBase className={classes.buttoBase} onClick={click} disabled={disabled}>
        <Icon className={classes.colorIcon}>{icon}</Icon>
        {width > config.RESPONSIVE_MOBILE ? (
          <>
            <Typography className={classes.buttonBaseText}>{titleButton}</Typography>
            <ArrowDropDownIcon className={classes.colorIcon} />
          </>
        ) : null}
      </ButtonBase>
      <Menu
        keepMounted
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        id="customized-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        <Box>
          <Typography className={classes.tileModal}>{titleMenu}</Typography>
          <Divider />
          {children}
        </Box>
      </Menu>
    </>
  );
}
