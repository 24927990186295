
import React, { useEffect, useState } from 'react';
import Slider from "react-slick";

import { useTranslation } from 'react-i18next';
import { truncate } from 'lodash';
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import { useSelector } from "react-redux"
import { 
  Card, 
  makeStyles, 
  CardMedia, 
  useTheme 
} from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import ImagineSlider from "./../../components/Shared/ImagineSlider"
import './css/challengeBanner.css';
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';
import { history } from '../../helpers';

const useStyles = makeStyles((theme) => ({
  cardSeeAll: { 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(2),
  },
  title: {
    color: theme.palette.neutrals.low.main,
  },
  textSeeAll: {
    fontSize: 12,
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.light,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    },
  },
  challengeCard: {
    borderRadius: 8,
    border: '1px solid',
    padding: "0",
    borderColor: theme.palette.grey.main,
    backgroundColor: "#fff",
    height: "100%",
  },
  cardMedia: {
    width: '100%',
    // height: 158,
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    objectFit: "cover",
  },
  contentInner: {
    width: "100%",
    height: "100%",
    padding: "0",
    position: "sticky",
    background: theme.palette.neutrals.high.main,
    bottom: 0,
  },
  titleBanner: {
    cursor: 'pointer',
    color: theme.palette.neutrals.low.main,
    fontWeight: 'bold',
    fontSize: 17,
    textAlign: 'left',
    marginBottom: "0.5rem",
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  description: {
    color: theme.palette.neutrals.low.light,
    fontSize: '12px',
    fontWeight: 500,
    wordBreak: 'break-word',
    textAlign: 'left'
  },
  badgeStatus: {
    position: 'absolute',
    top: 16,
    left: 16,
    fontSize: 11,
    fontWeight: 600,
    textTransform: 'uppercase',
    padding: 5,
    borderRadius: 4,
    color: theme.palette.neutrals.high.main,
    backgroundColor: theme.palette.auxiliary.cyan.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 8,
    },
  },
  imageContainer: {
    position: "relative",
  },
  divider: {
    margin: "0.5rem 0"
  },
  winnersList: {
    paddingLeft: "1rem"
  },
  winnersListItem: {
    textAlign: "left",
    fontSize: 11,
    color: theme.palette.text.dark,
    fontWeight: 400,
    "&::marker": {
      content: "",
      width: 4,
      height: 4,
      borderRadius: "50%",
      backgroundColor: "#868E96",
      "&:nth-child(even)": {
        backgroundColor: "#FF5722"
      }
    }
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#CED4DA",
    transition: "all"
  },
  dotSelected: {
    with: 32,
    height: 8,
    borderRadius: 8,
    transition: "all"
  }
}));

function ChallengeBanner() {
  const challenges = useSelector((state) => state.timeline.challenges);
  const [ filteredChallenges, setFilteredChallenges ] = useState([])
  const classes = useStyles();
  const theme = useTheme();
  const urlImage = (challenge) => challenge?.thumbnail?.url ? challenge?.thumbnail?.url : challenge?.attachment?.url;

  const { t } = useTranslation();

  function goToChallenge(id) {
    return () => {
      history.push(`/challenge/detail/${id}`);
    }
  }

  function handleSeeAll() {
    history.push('/challenges')
  }

  useEffect(() => {
    if (challenges.length > 0) {
      setFilteredChallenges(challenges.filter(x => x.statusChallenge?.code === 4))
    }
  }, [ challenges ])

  return (
    <Grid container spacing={2} style={{ marginBottom: theme.spacing(1) }}>
      <Grid item xs={12}>
        <Card className={classes.cardSeeAll} elevation={0}>
          <Box display="flex" alignItems="center">
            <FlagIcon style={{ color: theme.palette.auxiliary.blue.main, marginRight: 6 }} />
            <Typography variant="subtitle2" className={classes.title}>{t('desafios')}</Typography>
          </Box>
          <Typography className={classes.textSeeAll} onClick={handleSeeAll}>
            {t('ver_todos')}
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <ImagineSlider>
          {filteredChallenges.length > 0 && filteredChallenges.map((challenge, index) => (
            <Box className={classes.challengeCard} key={index}>
              <Box className={classes.imageContainer}>
                <img src={ urlImage(challenge) || imageCard } alt={challenge.name} className={classes.cardMedia}/>
                <Box className={classes.badgeStatus}>{ challenge.statusChallenge.name }</Box>
              </Box>
              <Box display="flex" flexDirection="column" padding="1rem">
                <Typography component={"h1"} className={classes.titleBanner} onClick={goToChallenge(challenge._id)}>{truncate(challenge.name, { length: 24, separator: '...' })}</Typography>
                <Typography component={"p"} className={classes.description}>{truncate(challenge.description, { length: 103, separator: '...' })}</Typography>
                {/* <Divider className={classes.divider}/> */}
                <ul className={classes.winnersList}>
                { challenge.rewards.map((item, index) => ( item?.winner?.userName && (
                  <li key={item._id} className={classes.winnersListItem}>
                    { index+1 }º&nbsp; 
                    { item.description }
                    &nbsp;
                    -
                    &nbsp;
                    { item.winner?.userName }
                  </li>
                )
                ))}
                </ul>
              </Box>
            </Box>
          ))}
        </ImagineSlider>
      </Grid>
    </Grid> 
  );
}

export { ChallengeBanner };
