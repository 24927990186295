import React from 'react';
import {
  DialogContent,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { 
  ideaCreateActions, 
  ideaCreateDialogsActions, 
  multiAreaCascadeActions 
} from '../../actions';
import config from '../../config';
import { dialogCss } from './dialogCss';
import { DialogCreateIdea } from './DialogCreateIdea';
import { DialogCustomTitle } from './DialogCustomTitle';
import clsx from 'clsx';
import { uniqueId } from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useCreateIdeaController } from './hooks/useCreateIdeaController';
import { CreateIdeaActions } from './CreateIdeaActions';

const useStyles = makeStyles(() => ({
  dividerBottom: {
    borderBottom: 'none',
  },
}));

function DialogChallenge({ styles }) {
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const dispatch = useDispatch();
  let { Challenge } = useSelector((state) => state.domains);
  const { dialogChallenge, fromHistory } = useSelector(
    (state) => state.ideaCreateDialogs
  );
  const idea = useSelector((state) => state.ideaCreate);
  const { handleSubmit, register } = useForm();
  const notChallenge = {
    _id: uniqueId(),
    name: 'Ideia Aberta',
    notChallenge: true,
  };

  const { t } = useTranslation();
  const { previous, close } = useCreateIdeaController();
  
  function onSubmit() {
    if (fromHistory === 'sendIdea') {
      dispatch(ideaCreateDialogsActions.openDialogSendIdea());
      return;
    }
    
    if (config.PERSONALIZED_CLASSIFICATION_COLABORATOR) {
      dispatch(ideaCreateDialogsActions.openDialogClassification('DIALOG_CHALLENGE'));
    } else {
    dispatch(ideaCreateDialogsActions.openDialogSendIdea());
    };
  }

  function selectedChallenge(challenge) {
    dispatch(ideaCreateActions.setChallenge(challenge));
  }
  
  return (
    <DialogCreateIdea open={dialogChallenge}>
      <DialogCustomTitle goBack={previous} onClose={close}>
        {t('para_qual_desafio_e_a_sua_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <FormLabel component="legend" className={dialogClasses.subtitle}>
            {t('se_sua_ideia_responde_a_um_desafio_selecione_a')}, {' '}
            {t('se_nao_selecione_uma_ideia_aberta')}.
          </FormLabel>
          <RadioGroup aria-label="challenge" name="challenge">
            {Challenge &&
              Challenge?.filter(item => item.statusChallenge?.code === 4 ).map((challenge) => (
                <FormControlLabel
                  key={challenge._id}
                  value={challenge._id}
                  className={clsx(classes.label, dialogClasses.label)}
                  control={<Radio color="primary" />}
                  onChange={() => selectedChallenge(challenge)}
                  label={challenge.name}
                  inputRef={register}
                  checked={idea?.challenge?.name === challenge?.name}
                />
              ))}
            {config.ALLOW_IDEIAS_WITHOUT_CHALLENGE && (
              <FormControlLabel
                key={idea?.challenge?._id}
                value={idea?.challenge?._id}
                className={clsx(classes.label, dialogClasses.label)}
                control={<Radio color="primary" />}
                label={ t("ideia_aberta") }
                onChange={() => selectedChallenge(notChallenge)}
                inputRef={register}
                checked={idea?.challenge?.name === notChallenge?.name}
              />
            )}
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <CreateIdeaActions name="DIALOG_CHALLENGE" onSubmit={handleSubmit(onSubmit)} />
    </DialogCreateIdea>
  );
}

export { DialogChallenge };
