import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Table,
  TableBody,
  Box,
  TablePagination,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { tableCss } from './../Tables/TableCss';
import { useTranslation } from 'react-i18next';
import { ReportEmptyState } from './ReportEmptyState';
import { useReportFiltersController } from './hooks/useReportFiltersController';
import { IdeasWithEvaluatedStepsRow } from './ReportsWithCollapseRow/IdeasWithEvaluatedStepsRow';

export const TablePanel = ({ tableInfo }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const tableClasses = tableCss({
    text: t('linhas_por_pagina'),
  });
  const classes = useStyles();
  const {
    report,
    paging,
    loading,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSelectedColumnChecked
  } = useReportFiltersController();
  const hasReport = report.length > 0;

  return (
    <Fragment>
      {loading && (
        <Paper className={classes.noContent} elevation={0}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {t('carregando_informacoes')}
          </Box>
        </Paper>
      )}

      {!loading && !hasReport && <ReportEmptyState />}
      
      {!loading && hasReport && (
        <Fragment>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="report table"
              classes={{ root: tableClasses.table }}
            >
              <TableHead>
                <TableRow>
                  {tableInfo.columns.map((column) => {
                    if (handleSelectedColumnChecked(column.id)) return <TableCell
                      key={column.id}
                      classes={{ root: tableClasses.tableCellHeader }}
                    >
                      {column.label}
                    </TableCell>
                  }
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <>
                  {pathname === '/report/ideas-with-evaluated-steps' ? (
                    tableInfo.data.map((item, index) => (
                      <IdeasWithEvaluatedStepsRow row={item} key={index} />
                    ))
                  ) : (
                    tableInfo.data.map((item, index) => (
                      <TableRow
                        hover
                        key={index}
                        classes={{ root: tableClasses.tableRow }}
                      >
                        {item}
                      </TableRow>
                    ))
                  )}
                </>
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            classes={{
              input: tableClasses.paginationInput,
              spacer: tableClasses.paginationSpacer,
              caption: tableClasses.paginationCaption,
              toolbar: tableClasses.paginationToolbar,
            }}
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            count={paging.total}
            rowsPerPage={paging.limit}
            page={paging.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('exibindo')}
          />
        </Fragment>
      )}
    </Fragment>
  );
}
