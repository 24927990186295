import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Grid,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  Avatar,
  useTheme,
  Button,
  Divider
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import GroupIcon from '@material-ui/icons/Group';
import AttachmentIcon from '@material-ui/icons/Attachment';

import { CanvasRead, ValueCanvasRead } from '../CreateIdeaDialogs';
import { StatusIdea, IdeaApproverTable } from '../../components';
import { AttachmentFiles } from "./AttachmentFiles"
import { AttachmentVideo } from "./AttachmentVideo"
import { AttachmentImages } from "./AttachmentImages";
import { Author, AvatarGroupIdea, MenuInformation, StepIdea } from '../Shared';
import { ImagineLinkify } from "../TextLinkify";
import { ideaActions } from '../../actions';
import { history, masker } from '../../helpers';
import { IdeaCss } from './IdeaCss';
import { ReactComponent as ValuePropositionCanvasIcon } from '../../assets/imgs/icon-valuepropositioncanvas.svg';
import { ReactComponent as InfraStructureIcon } from '../../assets/imgs/infrastucture-icon.svg';
import { useIdeaDetailsController } from '../../containers/IdeaDetailsPage/hooks/useIdeaDetailsController';
import { AttachMoney } from '@material-ui/icons';
import config from '../../config';

function IdeaGeneral() {
  const classes = IdeaCss();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isOpenCanvas, setIsOpenCanvas] = useState(false)
  const [isOpenValueCanvas, setIsOpenValueCanvas] = useState(false)
  const { ideasStepsFlux } = useSelector(state => state.domains);
  const user = useSelector(state => state.user);
  const { t } = useTranslation();
  const { hasIdeaFlux, stepflux, ideaDetailsFlux } = useIdeaDetailsController();

  function handleOnOpenValueCanvas() {
    setIsOpenValueCanvas(true)
  }

  function handleOnCloseValueCanvas() {
    setIsOpenValueCanvas(false)
  }

  function handleOnOpenCanvas() {
    setIsOpenCanvas(true)
  }

  function handleOnCloseCanvas() {
    setIsOpenCanvas(false)
  }

  function handleChange(status) {
    dispatch(ideaActions.sendTimelineView(hasIdeaFlux._id, status));
  }

  function isFillCanvas(canvas) {
    if (!!canvas.length) {
      const hasAnswer = canvas.map((item) => item.answer?.length).some((length) => length > 0);
      return hasAnswer;
    };
    return false
  };

  function goToChallenge() {
    history.push(`/challenge/detail/${hasIdeaFlux.challenge._id}`);
  }

  return (
    <>
      <CanvasRead idea={hasIdeaFlux} isOpen={isOpenCanvas} onClose={handleOnCloseCanvas} />
      <ValueCanvasRead idea={hasIdeaFlux} isOpen={isOpenValueCanvas} onClose={handleOnCloseValueCanvas} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className={classes.titleUpperCase}>
            {t('ideia_geral')}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Card elevation={0} className={classes.card} style={{ height: '100%' }}>
            <Grid container spacing={2} className={classes.ideaGeneralField} >
              <Grid item xs={12} style={{ marginLeft: -16, marginTop: -16 }}>
                {hasIdeaFlux.contributors.length > 0 ? (
                  <AvatarGroupIdea user={hasIdeaFlux.user} idea={hasIdeaFlux} />
                ) : (
                  <Author
                    activeUser={hasIdeaFlux.user?.status}
                    isTimeline={true}
                    userAuthor={hasIdeaFlux.user}
                    timelineDate={hasIdeaFlux.timelineDate}
                  />
                )}
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle2">{hasIdeaFlux.name}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body2">
                  <ImagineLinkify>
                    {hasIdeaFlux.description}
                  </ImagineLinkify>
                </Typography>
              </Grid>

              {stepflux && hasIdeaFlux?.problem && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    {t('problema_identificado')}
                  </Typography>
                  <Typography variant="body2">
                    {hasIdeaFlux?.problem}
                  </Typography>
                </Grid>           
              )}

              {stepflux && hasIdeaFlux?.solution && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" gutterBottom>
                    {t('solucao_proposta')}
                  </Typography>
                  <Typography variant="body2">
                    {hasIdeaFlux?.solution}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card elevation={0} className={classes.cardSmall}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Avatar className={classes.avatarIcon}>
                        <DescriptionIcon color="primary" fontSize="small" />
                      </Avatar>
                      <Typography variant="subtitle1">
                        {t('detalhes')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">{stepflux ? t('etapa') : t('status')}</Typography>
                    {stepflux ?
                        <StepIdea step={ideasStepsFlux.find(({ _id }) => _id === ideaDetailsFlux?.currentStepFlux?.ideaStepFlux?._id) || ideaDetailsFlux?.statusIdea} />
                        :                    
                        <StatusIdea status={hasIdeaFlux.statusIdea} />
                      }
                  </Grid>
                  {hasIdeaFlux?.code && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{t('codigo')}</Typography>
                      <Typography variant="body2">{hasIdeaFlux.code}</Typography>
                    </Grid>
                  )}
                  {hasIdeaFlux.challenge?.name && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{t('desafio')}</Typography>
                      <Typography variant="body2" className={classes.challengeLink} onClick={goToChallenge}>
                        {hasIdeaFlux.challenge.name}
                      </Typography>
                    </Grid>
                  )}
                  {hasIdeaFlux?.personalizedClassifications && hasIdeaFlux?.personalizedClassifications.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">{t('classificacao')}</Typography>
                      <Box display="flex" flexDirection="column" justifyContent="flex-start">
                        {hasIdeaFlux?.personalizedClassifications?.map((classification, index) => (
                          <Typography variant="body2" key={index}>
                            - {classification.name}
                          </Typography>
                        ))}
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>

            {(isFillCanvas(hasIdeaFlux.canvas) || isFillCanvas(hasIdeaFlux.canvasValueProposition)) && (
              <Grid item xs={12}>
                <Card elevation={0} className={classes.cardSmall}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatarIcon}>
                          <DashboardRoundedIcon color="primary" fontSize="small" />
                        </Avatar>
                        <Typography variant="subtitle1">
                          {t('canvas')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {isFillCanvas(hasIdeaFlux.canvasValueProposition) && (
                        <Button
                          onClick={handleOnOpenValueCanvas}
                          className={classes.buttonCanvas}
                          startIcon={<ValuePropositionCanvasIcon style={{ fill: theme.palette.neutrals.low.dark }} />}
                        >
                          Value Proposition Canvas
                        </Button>
                      )}
                      {isFillCanvas(hasIdeaFlux.canvas) && (
                        <Button
                          onClick={handleOnOpenCanvas}
                          className={classes.buttonCanvas}
                          startIcon={<DashboardOutlinedIcon style={{ fontSize: 24 }} />}
                        >
                          Business Model Canvas
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {isFillCanvas(hasIdeaFlux.canvasValueProposition) && (
                      <Button
                        onClick={handleOnOpenValueCanvas}
                        className={classes.buttonCanvas}
                        startIcon={<ValuePropositionCanvasIcon style={{ fill: theme.palette.neutrals.low.dark }} />}
                      >
                        Value Proposition Canvas
                      </Button>
                    )}
                    {isFillCanvas(hasIdeaFlux.canvas) && (
                      <Button
                        onClick={handleOnOpenCanvas}
                        className={classes.buttonCanvas}
                        startIcon={<DashboardOutlinedIcon style={{ fontSize: 24 }} />}
                      >
                        Business Model Canvas
                      </Button>
                    )}
                  </Grid>
                </Card>
              </Grid>
            )}

            {hasIdeaFlux.contributors?.length > 0 && (
              <Grid item xs={12}>
                <Card elevation={0} className={classes.cardSmall}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center">
                        <Avatar className={classes.avatarIcon}>
                          <GroupIcon color="primary" fontSize="small" />
                        </Avatar>
                        <Typography variant="subtitle1">
                          {t('participantes')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {hasIdeaFlux.contributors?.length > 0 && hasIdeaFlux.contributors.map((contributor, index) => (
                        <Box display="flex" alignItems="center" marginBottom="1rem" key={index}>
                          {contributor.attachment?.url ? (
                            <Avatar
                              className={classes.contributorImage}
                              src={contributor.attachment?.url}
                            />
                          ) : (
                            <Avatar
                              className={classes.contributorImage}
                              style={{ backgroundColor: theme.palette.primary.light }}
                            >
                              <PersonIcon style={{ color: theme.palette.primary.main }} />
                            </Avatar>
                          )}
                          <Typography
                            variant="subtitle2"
                            className={classes.contributorName}
                            onClick={() => history.push(`/profile/${contributor._id}`)}
                          >
                            {contributor.name}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
          </Grid>
        </Grid>
        {stepflux && (hasIdeaFlux?.estimatedExecutionCost > 0 && hasIdeaFlux?.estimatedFinancialReturn > 0) && (
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Avatar className={classes.avatarIcon}>
                      <AttachMoney style={{ fill: theme.palette.primary.main }} />
                    </Avatar>
                    <Typography variant="subtitle1">
                      {t('retorno_financeiro')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">{t('custos_previstos')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">{t('retorno_previsto')}</Typography>
                    </Grid>
                  </Grid>
                    <Grid container spacing={4} display="flex" alignItems="center">
                      <Grid item xs={4}>
{hasIdeaFlux?.estimatedExecutionCost && (
                        <Typography variant="body2">{masker(hasIdeaFlux.estimatedExecutionCost.toFixed(2))}</Typography>
)}
                      </Grid>
                      <Grid item xs={4}>
{hasIdeaFlux?.estimatedFinancialReturn && (
                        <Typography variant="body2">{masker(hasIdeaFlux.estimatedFinancialReturn.toFixed(2))}</Typography>
)}
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}

        {(hasIdeaFlux.links?.length > 0 || hasIdeaFlux.attachments?.length > 0) && (
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Avatar className={classes.avatarIcon}>
                      <AttachmentIcon color="primary" fontSize="small" />
                    </Avatar>
                    <Typography variant="subtitle1">
                      {t('anexos')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    style={{ fontWeight: 500, color: theme.palette.auxiliary.indigo.main }}
                  >
                    {hasIdeaFlux.links?.length > 0 && (
                      <ImagineLinkify>{hasIdeaFlux.links}</ImagineLinkify>
                    )}
                  </Typography>
                  {hasIdeaFlux.attachments?.length > 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <AttachmentFiles attachments={hasIdeaFlux.attachments} />
                        <AttachmentVideo attachments={hasIdeaFlux.attachments} />
                        <AttachmentImages attachments={hasIdeaFlux.attachments} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}

        {hasIdeaFlux?.businessUnitLocal?.length > 0 && (
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Avatar className={classes.avatarIcon}>
                      <InfraStructureIcon style={{ fill: theme.palette.primary.main }} />
                    </Avatar>
                    <Typography variant="subtitle1">
                      {t('abrangencia')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">{t('unidade_de_negocio')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">{t('local')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle2">{t('area_de_atuacao')}</Typography>
                    </Grid>
                  </Grid>
                  {hasIdeaFlux.businessUnitLocal.map((businessUnitLocal, index) => (
                    <Grid container spacing={4} key={index} display="flex" alignItems="center">
                      <Grid item xs={4}>
                        <Typography variant="body2">{businessUnitLocal?.businessUnit?.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">{businessUnitLocal?.local?.name}</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant="body2">{businessUnitLocal?.occupationArea?.name}</Typography>
                      </Grid>
                      {index !== (hasIdeaFlux.businessUnitLocal.length - 1) && ( 

                        <Grid item xs={12} style={{ paddingTop: 0 }}>
                          <Divider />
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        )}

        {hasIdeaFlux?.currentStepFlux?.evaluatorsInStep?.length > 0 && (
          <>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Typography className={classes.titleUpperCase}>
                  {t('qualificadores_atribuidos')}
                </Typography>
                <MenuInformation>
                  {t('possiveis_qualificadores_para_essa_ideia_sao_os_gestores_da_area_de_abrangencia_vinculada')}
                </MenuInformation>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <IdeaApproverTable />
            </Grid>
          </>
        )}

        {(user.typeProfile.type === ('admin' || 'manager') && hasIdeaFlux?.statusIdea?.code !== "AWAITING_QUALIFICATION") && (
          <Grid item xs={12}>
            <FormGroup>
              <FormControlLabel
                label={t('exibir_ideia_no_feed')}
                control={
                  <Switch
                    color="primary"
                    checked={hasIdeaFlux?.timeline}
                    onChange={() => handleChange(!hasIdeaFlux?.timeline)}
                  />
                }
              />
            </FormGroup>
          </Grid>
        )}

      </Grid>
    </>
  );
}

export { IdeaGeneral };
