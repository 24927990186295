import { Avatar, Box, Button, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { AddCircle, BusinessCenter } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { truncate } from "../../helpers";
import { tableCss } from "../Tables/TableCss";
import CardMenu from "../Portfolio/CardMenu/CardMenu";
import { StatusIdea } from "../Shared";
import { useTranslation } from "react-i18next";
import { DialogCreateProject } from "../Portfolio";
import { portfolioDialogProjectActions } from "../../actions/portfolio/portfolio-dialog-project.actions";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    padding: theme.spacing(2, 2, 1),
    color: theme.palette.neutrals.low.dark
  },

  linkedProjectCard: {
    backgroundColor: theme.palette.neutrals.high.main,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    marginBottom: 24,
    padding: 32
  },

  cardLinkedProjectIcon: {
    borderRadius: '6rem',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.lightest,
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 18
  },

  cardAddTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 32
  }
}));

function LinkedProjects({ projects }) {
  const tableClasses = tableCss();
  const classes = useStyles();
  const { t } = useTranslation();
  const { statusIdea } = useSelector(state => state.domains);
  const [openDialogCreateProject, setOpenDialogCreateProject] = useState(false);
  const { ideaDetails } = useSelector((state) => state.idea);
  const dispatch = useDispatch();


  const columns = [
    { id: 'project', label: t('projeto') },
    { id: 'team', label: t('equipe') },
    { id: 'initDate', label: t('início') },
    { id: 'endDate', label: t('término') },
    { id: 'progress', label: t('progresso') },
    { id: 'status', label: t('status') },
    { id: 'actions', label: t('') }
  ];

  function handleOpenDialog() {
    dispatch(portfolioDialogProjectActions.clearCreateProject());
    setOpenDialogCreateProject(!openDialogCreateProject);
    dispatch(portfolioDialogProjectActions.changeField('idea', { label: ideaDetails.name, id: ideaDetails._id }));
  };

  function ProjectsLinkedTable({project}) {
    const totalTasks = project?.tasks?.length;
    const concludedTasks = project.tasks.filter(item => item.taskStatus.code === "CONCLUDED").length;

    return (
      <TableRow
        hover
        key={project?._id}
        classes={{ root: tableClasses.tableRow }}
      >
        <TableCell
          classes={{ root: tableClasses.tableCellRadiusLeft }}
          style={{ border: 'none' }}
        >
          {truncate(project?.name, 30)}
        </TableCell>
        <TableCell
          className={tableClasses.oneLine}
          classes={{ root: tableClasses.tableCell }}
          style={{ border: 'none' }}
        >
          <Avatar alt={project?.user?.name} src={project?.user?.attachment?.url} />
        </TableCell>
        <TableCell classes={{ root: tableClasses.tableCell }}
          style={{ border: 'none' }}

        >
          {moment(project?.dateInit).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell
          style={{ border: 'none' }}
          className={tableClasses.oneLine}
          classes={{ root: tableClasses.tableCell }}
        >
          {moment(project?.dateEnd).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell
          style={{ border: 'none' }}

          className={tableClasses.oneLine}
          classes={{ root: tableClasses.tableCell }}
        >
          {`${concludedTasks}/${totalTasks}`}
        </TableCell>
        <TableCell
          style={{ border: 'none' }}
          className={tableClasses.oneLine}
          classes={{ root: tableClasses.tableCell }}
        >
          <StatusIdea status={statusIdea.filter(({ code }) => code === project?.status?.code)[0]} />
        </TableCell>
        <TableCell
          style={{ border: 'none' }}
          classes={{ root: tableClasses.tableCellRadiusRight }}>
          <CardMenu isExecutionFLow={true} project={project} />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Grid item xs={12}>
      <DialogCreateProject executionFlow={true} open={openDialogCreateProject} onClose={handleOpenDialog} title={ideaDetails.name} />
      <Box className={classes.linkedProjectCard}>
        <Box className={classes.cardAddTitle}>
          <Box display='flex' alignItems='center'>
            <Box className={classes.cardLinkedProjectIcon}>
              <BusinessCenter />
            </Box>
            <Typography style={{ fontWeight: 600, fontSize: 20 }}>
              {t('projetos_vinculados')}
            </Typography>
          </Box>
          <Button onClick={handleOpenDialog} startIcon={<AddCircle />} variant='text' color='primary'>{t('adicionar_projeto')}</Button>
        </Box>
        <Paper className={tableClasses.paperRoot}>
          <TableContainer>
            <Table
              classes={{ root: tableClasses.table }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      classes={{ root: tableClasses.tableCellHeader }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project, index) => <ProjectsLinkedTable project={project} key={index} />)}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </Grid>
  );
};

export { LinkedProjects };
