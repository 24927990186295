import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1),
    color: theme.palette.neutrals.low.medium,
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  icon: {
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
  },
  line: {
    borderLeft: '3px solid',
    height: 16,
    position: 'absolute',
    left: 27,
    top: -8,
  },
  containerButton: {
    marginTop: 30,
  },
  circleProgress: {
    color: theme.palette.primary.main, 
    marginRight: 10 
  }
}));