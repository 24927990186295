import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  Box
} from '@material-ui/core';
import { DialogCustomTitle } from './DialogCustomTitle';
import { ideaCreateDialogsActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Upload, UploadButton } from '../Upload';
import InfoIcon from '@material-ui/icons/Info';
import { dialogCss } from './dialogCss';
import dialogPhotoSvg from '../../assets/imgs/dialog-photo.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { DialogCreateIdea } from './DialogCreateIdea';
import { useTranslation } from 'react-i18next';
import { useCreateIdeaController } from './hooks/useCreateIdeaController';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  info: {
    paddingBottom: theme.spacing(3),
    color: theme.palette.grey[500],
  },
  button: {
    textTransform: 'lowercase',
  },
  alert: {
    color: theme.palette.grey[500],
    paddingBottom: 24,
  },
  strong: {
    fontWeight: 'bold',
  },
  dividerBottom: {
    borderBottom: 'none',
  },
}));

function DialogPhoto() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const { dialogPhoto, fromHistory } = useSelector((state) => state.ideaCreateDialogs);
  const { t } = useTranslation();
  const { previous, close } = useCreateIdeaController();

  function next() {
    if (fromHistory.length > 0) {
      dispatch(ideaCreateDialogsActions.previousDialog())
      return;
    }

    dispatch(ideaCreateDialogsActions.openDialogTitleDescription());
  }

  return (
    <DialogCreateIdea open={dialogPhoto}>
      <DialogCustomTitle goBack={previous} onClose={close}>
        {t('publicar_foto')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <Upload
          type="photo"
          img={dialogPhotoSvg}
          name="foto"
          message={t('insira_imagens_como_referencia_da_sua_ideia')}
          accept="image/png, image/jpeg"
          maxSize={5242880}
        />
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <div style={{ width: '100%' }}>
          <Box display="flex" alignItems="center" className={classes.info}>
            <InfoIcon fontSize="small" style={{ marginRight: 6 }} />
            <Typography variant="caption">
              {t('o_arquivo_deve_ser_em_formato')}{' '}
              <strong>png</strong>{' '}{t('e')}{' '}
              <strong>jpg</strong> {' '}{t('e')}{' '}
              {t('possuir_tamanho_maximo_de')}{' '}<strong>5mb</strong>.
            </Typography>
          </Box>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {fromHistory.length > 0 && (
                <Button
                  color="primary"
                  onClick={previous}
                  startIcon={<ArrowBackIcon />}
                  className={dialogClasses.button}
                >
                  {t('voltar')}
                </Button>
              )}
            </div>
            <div>
              <UploadButton
                type="photo"
                text={t('adicionar_imagem')}
                icon={<PhotoCameraIcon />}
                className={dialogClasses.button}
              />
              <Button
                className={dialogClasses.button}
                variant="contained"
                color="primary"
                startIcon={<ArrowForwardIcon />}
                onClick={() => next()}
              >
                {t('avancar')}
              </Button>
            </div>
          </div>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogPhoto };
