import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import clsx from 'clsx';

import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { tableCss } from './../../Tables/TableCss';
import { CustomTooltip } from '../../Shared';
import { truncate } from '../../../helpers';
import { styles } from './styles';
import { TableHead } from '@material-ui/core';

const TableRowStyled = withStyles({
  root: {
    borderRight: `1px solid #E7EaED`,
    borderLeft: `1px solid #E7EaED`,
  },
})(TableRow);

export function IdeasWithEvaluatedStepsRow({ row }) {
  const history = useHistory();
  const classes = styles();
  const tableClasses = tableCss();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow className={clsx(classes.root, tableClasses.tableRow)}>
        <TableCell style={{ width: 500 }} classes={{ root: tableClasses.tableCellRadiusLeft }}>
          <Box display="flex" alignItems="center">
            {row.evaluationStepsHistory.length > 0 && (
              <IconButton aria-label="expand row" size="small" style={{ marginRight: 15 }} onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
            <CustomTooltip title={row.name} aria-label={row.name} placement="bottom-start">
              <Typography 
                variant="subtitle2" 
                className={tableClasses.link} 
                onClick={() => history.push(`/idea/${row._id}/general`)}
              >
                {truncate(row.name, 60)}
              </Typography>
            </CustomTooltip>
          </Box>
        </TableCell>
        <TableCell align="left" classes={{ root: tableClasses.tableCell }}>
          {row.code}
        </TableCell>
        <TableCell align="left" classes={{ root: tableClasses.tableCellRadiusRight }}>
          {moment(row.creatdeAt).format('DD/MM/YYYY')}
        </TableCell>
      </TableRow>
      <TableRow className={tableClasses.tableRow}>
        <TableCell style={{ padding: 0 }} className={open ? tableClasses.tableRow : classes.hidden} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography variant="subtitle1" className={classes.titleContainer}>
              {t('historico_de_etapas')}
            </Typography>
            <Table size="medium" classes={{ root: tableClasses.table }}>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell>{t('nome_da_etapa')}</TableCell>
                  <TableCell>{t('diferenca_em_dias')}</TableCell>
                  <TableCell>{t('observacao')}</TableCell>
                  <TableCell>{t('data_de_criacao')}</TableCell>
                  <TableCell>{t('data_da_avaliacao')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row?.evaluationStepsHistory.map((step) => (
                  <TableRowStyled className={tableClasses.tableRow} key={step._id}>
                    <TableCell align="left" className={tableClasses.oneLine} style={{ width: 400 }}>
                      <IconButton size="small" classes={{ root: classes.root }}>
                        <SubdirectoryArrowRightIcon fontSize="small" />
                      </IconButton>
                      {step.stepName}
                    </TableCell>
                    <TableCell align="left" className={tableClasses.oneLine}>
                      {step.differenceInDays}
                    </TableCell>
                    <TableCell align="left" className={tableClasses.oneLine}>
                      {step.observation}
                    </TableCell>
                    <TableCell align="left">
                      {moment(step.createdAt).format('DD/MM/YYYY') }
                    </TableCell>
                    <TableCell align="left">
                      {moment(step.evaluatedAt).format('DD/MM/YYYY') }
                    </TableCell>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
