import { styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MuiCard from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AssignmentTurnedInOutlined from '@material-ui/icons/AssignmentTurnedInOutlined';
import { ReactComponent as ChecklistIcon } from '../../../assets/imgs/checklist-icon.svg';
import CheckIcon from '@material-ui/icons/Check';

export const Header = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
});

export const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4),
}));

export const GridItem = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  gap: 16
});

export const AvatarIcon = styled(Avatar)(({ theme }) => ({
  background: theme.palette.primary.light,
  color: theme.palette.primary.main,
  fill: theme.palette.primary.main,
}));

export const AvatarProgress = styled(Avatar)({
  width: 48,
  height: 48,
});

export const ButtonAddIcon = styled(Button)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  color: theme.palette.neutrals.low.dark
}));

export const ProgressTitleIcon = styled(AssignmentTurnedInOutlined)(({ theme }) => ({
  color: theme.palette.neutrals.high.main,
}));

export const ProgressChecklistIcon = styled(ChecklistIcon)(({ theme }) => ({
  fill: theme.palette.neutrals.high.main,
}));

export const ProgressCheckIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.neutrals.high.main,
}));