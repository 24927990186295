import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Grid, Typography, TextField, Checkbox, Avatar, Card, useTheme } from "@material-ui/core";
import { CheckBoxOutlineBlank, CheckBox as CheckboxIcon, AttachMoney } from '@material-ui/icons';
import { Autocomplete } from "@material-ui/lab";

import { StatusIdea, StepIdea } from "./../../components";
import { ideaEditStyles } from "./IdeaEditStyles";
import { ideaUpdateActions } from "./../../actions";
import { Author } from "./../../components/Shared";
import config from '../../config';
import { CurrencyMaskCustomInput } from '../../helpers';

export const IdeaEditGeneral = ({ ideaUpdate }) => {
  const isIdeaStepFlux = config.SHOW_IDEAS_BY_STEP_FLUX;
  const classes = ideaEditStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { typeProfile, _id: userId } = useSelector((state) => state.user);
  const theme = useTheme();

  function handleChange(e) {
    const { name, value } = e.target;
    dispatch(ideaUpdateActions.changeField(name, value));
  }

  function handleChallengeChange(data) {
    if (data) {
      dispatch(ideaUpdateActions.changeField('challenge', data.option));
    } else {
      dispatch(ideaUpdateActions.clearFieldChallenge(ideaUpdate.challenge));
    }
  }

  function handleChangeClassification(data) {
    if (data) {
      dispatch(ideaUpdateActions.changeFieldClassification(data.option));
    } else {
      dispatch(ideaUpdateActions.clearFieldClassification());
    }
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Author
            activeUser={ideaUpdate.user.status}
            paddingless={true}
            dateAndTime={ideaUpdate.createdAt}
            userAuthor={{...ideaUpdate.user, businessUnitLocal: ideaUpdate.businessUnitLocal}}
          />
        </Grid>
        <Grid item xs={12}>
        {!isIdeaStepFlux ? (
          <Box display="flex" alignItems="center" marginRight={8}>
            <Typography variant="subtitle2" className={classes.textStatus}>{t('status')}:</Typography>
            <StatusIdea status={ideaUpdate.statusIdea} />
          </Box>
        ) : (
          <Box display="flex" alignItems="center" marginRight={8}>
            <Typography variant="subtitle2" className={classes.textStatus}>{t('etapa_atual')}:</Typography>
            <StepIdea step={ideaUpdate.currentStepFlux.ideaStepFlux}/>
          </Box>
        )}
          
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="name"
            id="name"
            label={t('titulo')}
            autoComplete="name"
            type="text"
            variant="filled"
            fullWidth
            defaultValue={ideaUpdate.name}
            className={classes.box}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            id="description"
            label={t('descricao_ideia')}
            autoComplete="description"
            type="text"
            variant="filled"
            fullWidth
            multiline
            minRows={8}
            defaultValue={ideaUpdate.description}
            className={classes.box}
            onChange={handleChange}
          />
        </Grid>

       {isIdeaStepFlux && (<> 
        <Grid item xs={12}>

        <TextField
                className={classes.box}
                variant="filled"
                label={t('problema_identificado')}
                placeholder={t('descreva_qual_foi_oportunidade/problema _que_foi_identificado')}
                fullWidth
                onChange={handleChange}
                multiline
                minRows={8}
                name="problem"
                defaultValue={ideaUpdate.problem}
         />
        </Grid>
        
      <Grid item xs={12}>
        <TextField
                className={classes.box}
                variant="filled"
                label={t('solucao_proposta')}
                placeholder={t('descreva_como_se_pretende_resolver_o_problema_qual_a_solucao_seja_para_melhorar_ou_para_inovar')}
                fullWidth
                onChange={handleChange}
                multiline
                minRows={8}
                name="solution"
                defaultValue={ideaUpdate.solution}
          />
        </Grid>
        </>)}

        {(ideaUpdate?.contributors?.filter(item => item?._id === userId).length > 0 || ideaUpdate.user?._id === userId || typeProfile.type === 'admin') && (
          <>
            {ideaUpdate.challengesList.length > 0 && (
              <Grid item xs={12}>
                <Autocomplete
                  id="challenges-list"
                  loadingText={t('carregando')}
                  noOptionsText={t('sem_opcoes')}
                  clearText={t('excluir_desafio')}
                  clearOnEscape={true}
                  options={ideaUpdate.challengesList}
                  getOptionLabel={(option) => option.label || ''}
                  getOptionSelected={(_option, value) => ideaUpdate?.challenge === value}
                  renderInput={(params) => <TextField {...params} label={t('desafio')} variant="filled" />}
                  loading={ideaUpdate.challengesList.length === 0}
                  value={ideaUpdate?.challenge || null}
                  onChange={(_e, _items, _options, details) => handleChallengeChange(details)}
                />
              </Grid>
            )}
            {ideaUpdate?.classificationsList.length > 0 && (
              <Grid item xs={12}>
                <Autocomplete
                  multiple
                  fullWidth
                  disableCloseOnSelect
                  id="classification-list"
                  loadingText={t('carregando')}
                  noOptionsText={t('sem_opcoes')}
                  clearText={t('excluir_classificacao')}
                  clearOnEscape={true}
                  options={ideaUpdate?.personalizedClassifications}
                  getOptionLabel={(option) => option.name || ''}
                  getOptionSelected={(_option, value) => ideaUpdate.personalizedClassifications.filter(item => item._id === value._id)[0]}
                  renderInput={(params) => <TextField {...params} label={t('classificacao')} variant="filled" />}
                  loading={ideaUpdate.personalizedClassifications.length === 0}
                  value={ideaUpdate.personalizedClassifications.filter(item => item.checked)}
                  onChange={(_e, _items, _options, details) => handleChangeClassification(details)}
                  renderOption={(value) => {
                    return (
                      <li>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckboxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={value.checked}
                          />
                        {value.name}
                      </li>
                  )}}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
       {isIdeaStepFlux && (<> 
      <Grid container spacing={3} className={classes.root} style={{ marginTop: 30 }}>
                  <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Avatar className={classes.avatarIcon}>
                      <AttachMoney style={{ fill: theme.palette.primary.main }} />
                    </Avatar>
                    <Typography variant="subtitle1">
                      {t('estrutura_de_custos')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>   
                <Grid container spacing={4}>
                    <Grid item>
                    <CurrencyMaskCustomInput
                        name="estimatedExecutionCost"
                        type="text"
                        variant="filled"
                        label={t('custos_previstos')}
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={ideaUpdate.estimatedExecutionCost}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: '1.25rem', fontWeight: 600 },
                        }}
                        />
                    </Grid>
                    <Grid item>
                    <CurrencyMaskCustomInput
                        name="estimatedFinancialReturn"
                        type="text"
                        variant="filled"
                        label={t('retorno_previsto')}
                        onChange={handleChange}
                        onBlur={handleChange}
                        value={ideaUpdate.estimatedFinancialReturn}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                          style: { fontSize: '1.25rem', fontWeight: 600 },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
      </Grid>
      </>)}
    </Grid>
  );
}
