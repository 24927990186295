import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Card } from './styles';
import { AvatarIconWithText } from '../Shared';
import { TeamParticipants } from './TeamParticipants';
import { ReactComponent as TeamIcon } from '../../assets/imgs/teamicon.svg';

export function CardWithEvaluationTeams() {
  const { t } = useTranslation();
  const { teams } = useSelector(state => state.evaluationTeams);

  return (
    <Card elevation={0}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <AvatarIconWithText
            icon={<TeamIcon />}
            variant="h3"
            text={t('equipes_de_avaliacao')}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography variant="subtitle2">{t('nome_da_equipe')}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2">{t('participantes')}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="subtitle2">{t('status')}</Typography>
        </Grid>
        <Grid item xs={1}>
          {/* Actions */}
        </Grid>

        <Grid item xs={12}>
          {teams?.map((team, index) => (
            <TeamParticipants team={team} index={index} key={index} />
          ))}
        </Grid>
      </Grid>
    </Card>
  );
}